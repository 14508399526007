import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { ProductMarkdownRemark } from "../types/product-markdown";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/products/Hero";
import ProductCTA from "../components/Content/ProductCTA";
import ThreeImageRight from "../components/Content/ThreeImageRight";
import ThreeImageLeft from "../components/Content/ThreeImageLeft";
import TextCTA from "../components/Content/TextCTA";
import Testimonial from "../sections/landing1/Testimonial";
import ThreeTiles from "../components/Content/ThreeTiles";
import FAQ from "../components/Content/Faq";

type GraphQLResult = {
  markdownRemark: ProductMarkdownRemark;
};

const About: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const result = data.markdownRemark.frontmatter;

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{result.title}</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero result={result}></Hero>
        {/*<ProductCTA result={result} />*/}
        <ThreeImageRight
          text={result.featureText1}
          title={result.featureTitle1}
          options={result.featureOptions1}
        />
        <ThreeImageLeft
          text={result.featureText2}
          title={result.featureTitle2}
          options={result.featureOptions2}
          backgroundImage={result.featureBgImage2}
        />
        <TextCTA
          buttonText={result.ctaBtnText}
          text={result.ctaText}
          title={result.ctaTitle}
        />
        <ThreeImageRight
          text={result.featureText3}
          title={result.featureTitle3}
          options={result.featureOptions3}
        />
        {/*<ThreeTiles*/}
        {/*  tiles={result.tiles}*/}
        {/*/>*/}
        <Testimonial />
        <FAQ faqs={result.faqs} />
      </PageWrapper>
    </>
  );
};
export default About;

export const query = graphql`
  query product($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heroTitle
        heroText
        heroImage
        heroBg
        contentTitle
        contentText
        contentBtnText
        contentBtnTo
        contentBtnDownload
        featureTitle1
        featureText1
        featureOptions1 {
          title
          text
          image
          video
        }
        featureTitle2
        featureBgImage2
        featureText2
        featureOptions2 {
          title
          text
          image
        }
        featureTitle3
        featureText3
        featureOptions3 {
          title
          text
          image
        }
        faqs {
          title
          text
        }
        tiles {
          title
          text
          button {
            text
            to
          }
        }
        ctaTitle
        ctaText
        ctaBtnText
        ctaBtnLink
      }
    }
  }
`;
