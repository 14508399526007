import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../Core";
import Container from "../Layout/Container";

const Card = styled.div`
  border: #f0f9fe solid 3px;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    border: #f0f9fe solid 3px;
  }

  &.selected {
    background-color: #f0f9fe;
    &:hover {
      border: #f0f9fe solid 3px;
    }
  }
`;
const CardTitle = styled.h5`
  color: ${({ theme }) => theme.colors.text};
`;
const CardText = styled.p`
  font-size: 16px;
  line-height: 20px;
`;

interface Props {
  text: string;
  title: string;
  options: {
    title: string;
    text: string;
    image?: string;
    video?: string;
  }[];
  backgroundImage?: string;
}

const ThreeImageRight = ({ text, title, options = [], backgroundImage }: Props) => {
  const [selected, setSelected] = useState(0);

  return (
    <>
      {/* <!-- Content section 1 --> */}
      <Section style={{ position: 'relative' }}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
              <Box>
                <Title
                  fontSize="45px !important"
                  lineHeight="48px !important"
                  mb="10px !important"
                >
                  {title}
                </Title>
                <Text mb={4}>{text}</Text>
                {options.map(({ text, title }, index) => (
                  <Card
                    className={index === selected ? "selected" : undefined}
                    key={index}
                    onMouseEnter={() => setSelected(index)}
                  >
                    <CardTitle>{title}</CardTitle>
                    <CardText>{text}</CardText>
                  </Card>
                ))}
              </Box>
            </Col>
            <Col lg="1" className="order-lg-2" />
            <Col md="12" lg="5" className="order-lg-3">
              {options[selected]?.image && (
                <img src={options[selected]?.image}  style={{
                  width: 500,
                }} />
              )}
              {options[selected]?.video && (
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="500"
                  height="500"
                  style={{ aspectRatio: "500 / 500" }}
                >
                  <source data-src={options[selected]?.video} src={options[selected]?.video} type="video/mp4" />
                </video>
              )}
            </Col>
          </Row>
          {backgroundImage && (
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              width: '47%',
              backgroundImage: `url(${backgroundImage})`
            }} />
          )}
        </Container>
      </Section>
    </>
  );
};

export default ThreeImageRight;
