import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../Core";
import Container from "../../components/Layout/Container";

const SectionStyled = styled(Section)``;

interface FaqCardProps {
  title?: string;
  children: string;
}

interface ThemeProps {
  theme: DefaultTheme;
}

const FaqCard: React.FC<FaqCardProps> = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="600">
      {title}
    </Title>
    <Text variant="small">{children}</Text>
  </Box>
);

const FAQ = ({ faqs = [] }: { faqs: {
  title: string;
  text: string;
}[] }) => (
  <>
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col md="12">
            <div className="text-center">
              <Title>
                Need more information? Check out these FAQs
              </Title>
            </div>
          </Col>
        </Row>
        <Row>
          {faqs.map(({ title, text }, index) => (<Col key={index} lg="6" className="mt-5">
            <FaqCard title={title}>
              {text}
            </FaqCard>
          </Col>))}

          <Col lg="12" className="">
            <Box className="text-center" pt={5}>
              <Text variant="small">
                Didn’t find your answer?{" "}
                <a
                  href="/contact"
                  target="_blank"
                  // @ts-expect-error a does not expect css attribute
                  css={`
                    color: ${({ theme }: ThemeProps) =>
                      theme.colors.primary} !important;
                  `}
                >
                  Contact us here
                </a>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FAQ;
