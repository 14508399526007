import React from "react";
import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import {WhoMarkdownRemark} from "../../types/whoWeHelp-markdown";
import {Title, Section, Button} from "../../components/Core";
import Container from "../../components/Layout/Container";
import imgCase1 from "../../../assets/image/heros/town.png";

const SectionStyled = styled(Section)`
    /* background: url(${imgCase1}) no-repeat center right; */
  position: relative;
  background-color: #f0f9fe;
`;

const HalfBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 55%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  @media (max-width: 991px) {
    background-image: none !important;
  }
`;

const Text = styled.p`
  color: ${({theme}) => theme.colors.text};
  font-size: 20px;
`;

const Hero: React.FC<WhoMarkdownRemark> = ({result}: { result: any }) => {
  const {heroTitle, heroText, heroImage, heroBg} = result;

  return (
    <>
      <SectionStyled
        pt={["110px!important", null, "150px!important"]}
        pb={["50px!important", null, "80px!important"]}
      >
        <Container style={{zIndex: 2, position: 'relative'}}>
          <Row>
            <Col lg="6">
              <div>
                <Title variant="hero" color="#021d2d">
                  {heroTitle}
                </Title>
                <Text>
                  {heroText}
                </Text>
                <Button mt="30px" bg="primary" variant="solid">
                  Download Brochure
                </Button>
              </div>
            </Col>
            <Col lg="1"/>
            <Col lg="4">
              {!heroImage && !heroBg && <div
                  style={{
                    height: 400,
                    width: 400,
                    borderRadius: "100%",
                    backgroundColor: "#075c8d",
                  }}
              />}
              {heroImage && <img src={heroImage} style={{
                height: 420
              }}/>}
            </Col>
          </Row>
        </Container>
        {heroBg && <HalfBackground style={{backgroundImage: `url(${heroBg})`}}/>}
      </SectionStyled>
    </>
  );
};
export default Hero;
